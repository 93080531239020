<template src="@/assets/templates/CVEView.html" />
<style scoped src="@/assets/css/CVEView.css" />

<script>
import vSelect from 'vue-select'
import Paginate from 'vuejs-paginate-next';
import CVEDetail from './CVEDetail.vue'
import { mapActions } from 'vuex';

export default {
    name: 'CVEView',
    data() {
        return {
            pageCount: 0,
            pageNumber: 1,
            pageSize: { value: 10, name: '10 / page' },
            pageSizes: [
                { value: 10, name: '10 / page' },
                { value: 20, name: '20 / page' },
                { value: 50, name: '50 / page' },
                { value: 100, name: '100 / page' }
            ],
            product: null,
            product_list: [],
            cluster: null,
            cluster_list: [],
            component: null,
            component_list: [],
            image: null,
            image_list: [],
            severities: [],
            exploitable: null,
            exploitable_list: [{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }],
            scanners: [],
            scanner: null,
            package_types: [],
            package_type: null,
            severity_list: [],
            cve_name_filter: '',
            cve_list: [],
            componentCount: 0,
            imageCount: 0,
            cveCount: 0,
            showCVEDetail: false,
            cveInfo: null,
            selectedCveInfo: {},
            isExpandedMenuVisible: false,
            expandedItems: [],
        }
    },
    components: {
        vSelect,
        Paginate,
        CVEDetail,
    },

    methods: {
        ...mapActions(['toggleLoaderSpinner']),
        showLoader() {
            this.toggleLoaderSpinner(true);
        },
        hideLoader() {
            this.toggleLoaderSpinner(false);
        },

        toggleAdditionalMenu () {
            this.isExpandedMenuVisible = !this.isExpandedMenuVisible;
        },
        saveDataToCSV () {
            let csvHeaders = ['name', 'package', 'package_fixversion', 'package_type', 'package_path', 'scanner', 'artifact_name', 'artifact_id', 'component', 'component_id', 'severity', 'exploitable', 'comment', 'jira_task', 'fix_version_prd', 'scan_date', 'cvss2_score', 'cvss2_severity', 'cvss3_score', 'cvss3_severity'];

            let csvData  = csvHeaders.join("\t") + '\n';
            this.cve_list.forEach(item=>{
                csvHeaders.forEach( key => {
                    csvData += JSON.stringify(item[key]) + "\t";
                })
                csvData += '\n';
            })

            let file = new Blob([csvData], {type: 'text/csv;charset=utf-8;'});
            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file, "report.csv");
            } else {
                let a = document.createElement("a"),
                    url = URL.createObjectURL(file);
                a.href = url;
                a.download="report.csv";
                document.body.appendChild(a);
                a.style.visibility = 'hidden';
                a.click();
                setTimeout( () => {
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }, 0)
            }
        },
        generateUrl(imageName) {
        try {
            // Base URL components
            const baseUrl = 'https://us2.app.sysdig.com/secure/#/vulnerabilities/pipeline/';
            // Construct the filter query with the image name wrapped in quotes
            const filterQuery = `freeText in ("${imageName}")`;
            // Encode the filter query
            const encodedFilter = encodeURIComponent(filterQuery);
            // Construct the final URL
            return `${baseUrl}?filter=${encodedFilter}`;
        } catch (error) {
            return `Error generating URL: ${error.message}`;
        }
        },

        isAllItemsExpanded (elementList) {
            return (this.expandedItems.length < elementList.length);
        },
        toggleAllItemVisibility( elementList ) {
            if( this.expandedItems.length < elementList.length ) {
                this.expandedItems = Array.from ( {length: elementList.length}, (_, index)=>index  )
            } else {
                this.expandedItems = [];
            }
        },

        isElementVisible(element) {
            return this.expandedItems.indexOf(element) > -1;
        },
        toggleItemVisibility (element) {
            const index = this.expandedItems.indexOf(element);
            if (index === -1) {
                this.expandedItems.push(element);
            } else {
                this.expandedItems.splice(index, 1);
            }
        },

        setPageSize(item) {
            this.pageSize = item
            this.reloadCVEList()
        },
        severityClass(severity) {
            return severity ? severity.toLowerCase() : 'none'
        },
        productByComponent(component_id) {
            const product = this.product_list.filter((el) => {
                return el.components.filter((cmp) => { return cmp.id == component_id }).length > 0
            })
            if (product[0]) { return product[0].nameversion }
        },
        reasonByComponent(component_id) {
            const product = this.product_list.filter((el) => {
                return el.components.filter((cmp) => { return cmp.id == component_id }).length > 0
            })
            if (product[0]) { return product[0].create_reason }
        },
        reloadCVEList() {
            this.showLoader();

            const app = this
            const params = new URLSearchParams({ page: app.pageNumber })

            params.append('size', app.pageSize.value)
            if (app.cve_name_filter != '') { params.append('name_contains', app.cve_name_filter) }
            if (app.product) { params.append('products', [app.product.id]) }
            if (app.cluster) { params.append('clusters', [app.cluster.id]) }
            if (app.component) { params.append('components', [app.component.id]) }
            if (app.image) { params.append('images', [app.image.id]) }
            if (app.scanner) { params.append('scanners', [app.scanner]) }
            if (app.package_type) { params.append('package_types', [app.package_type.id]) }
            if (app.severities.length > 0) { params.append('severities', app.severities.map((el) => el.id)) }
            if (app.exploitable) { params.append('exploitable', app.exploitable.value) }

            app.$axios.get('/api/cve/', { params: params })
                .then(function (response) {
                    app.cveCount = response.data.count
                    app.pageCount = Math.ceil(response.data.count / app.$data.pageSize.value)
                    app.cve_list = []
                    response.data.results.forEach(cve => {
                        const cvss2_severity = app.severity_list.filter((el) => { return el.id == cve.cvss2_severity })[0]
                        const cvss3_severity = app.severity_list.filter((el) => { return el.id == cve.cvss3_severity })[0]
                        const component = app.component_list.filter((el) => { return el.id == cve.component })[0]
                        const severity = app.severity_list.filter((el) => { return el.id == cve.mira_severity })[0]
                        app.cve_list.push({
                            name: cve.name,
                            package: `${cve.package_name} (${cve.package_version})`,
                            package_fixversion: cve.fix_version,
                            package_type: app.package_types[cve.package_type - 1].name,
                            package_path: cve.package_path,
                            scanner: cve.scanner,
                            artifact_name: app.image_list.filter((el) => { return el.id == cve.image })[0]['name'],
                            artifact_id: cve.artifact_id,
                            component: component['nameversion'],
                            component_id: component['id'],
                            severity: severity['name'],
                            exploitable: cve.exploitable,
                            comment: cve.mira_comment,
                            jira_task: cve.jira_task,
                            fix_version_prd: cve.fix_version_prd,
                            scan_date: cve.date,
                            cvss2_score: cve.cvss2_score,
                            cvss2_severity: cvss2_severity ? cvss2_severity.name : '',
                            cvss3_score: cve.cvss3_score,
                            cvss3_severity: cvss3_severity ? cvss3_severity.name : '',
                            in_use: cve.in_use,
                        })
                    })
                })
                .finally(() => {
                    this.expandedItems = [];
                    this.hideLoader();
                });
            history.pushState(
                {},
                null,
                window.location.origin + "/#/cve?" + encodeURI(params)
            )
        },
        filterChanged() {
            this.$data.pageNumber = 1
            this.reloadCVEList()
        },
        reloadClusterList() {
            const app = this
            app.$data.cluster = null
            if (app.$data.product) {
                app.$data.cluster_list = app.$data.product.clusters
            } else {
                app.$axios.get('/api/cluster/')
                    .then(response => {
                        app.$data.cluster_list = response.data
                    })
                    .catch(error => console.log(error))
            }
            if (this.$route.query.clusters) {
                this.cluster = this.cluster_list.filter((el) => { return el.id == this.$route.query.clusters })[0]
            }
        },
        reloadComponentList() {
            const app = this
            app.$data.component = null
            if (app.$data.cluster) {
                app.$data.component_list = app.$data.cluster.components
                app.$data.componentCount = app.$data.component_list.length
                this.reloadImageList()
            } else if (app.$data.product) {
                app.$data.component_list = app.$data.product.components
                app.$data.componentCount = app.$data.component_list.length
                this.reloadImageList()
            } else {
                app.$axios.get('/api/component/')
                    .then(response => {
                        app.$data.componentCount = response.data.length
                        app.$data.component_list = response.data
                        this.reloadImageList()
                    })
                    .catch(error => console.log(error))
            }
            if (this.$route.query.components) {
                this.component = this.component_list.filter((el) => { return el.id == this.$route.query.components })[0]
            }
        },
        reloadImageList() {
            const app = this
            const params = new URLSearchParams()

            app.$data.image = null
            if (app.$data.component) {
                params.append('components', [app.$data.component.id])
            } else if (app.$data.cluster) {
                params.append('components', this.$data.cluster.components.map((el) => el.id))
            } else if (app.$data.product) {
                params.append('products', [app.$data.product.id])
            }
            app.$axios.get('/api/image/', { params: params })
                .then(response => {
                    app.$data.imageCount = response.data.length
                    app.$data.image_list = response.data
                    this.filterChanged()
                })
                .catch(error => console.log(error))
            if (this.$route.query.images) { this.image = this.image_list.filter((el) => { return el.id == this.$route.query.images })[0] }
        },
        getCVEDetail(cve_name, cve_detailed) {
            const component = cve_detailed.component.split(' ')[0]
            const version = cve_detailed.component.split(' ')[1]
            this.selectedCveInfo = {
                component: component,
                version: version,
                artifact: cve_detailed.artifact,
                artifact_id: cve_detailed.artifact_id
            };
            const app = this
            const params = new URLSearchParams({ cve_name: cve_name })
            app.$axios.get('/api/v2/cveinfo/components', { params: params })
                .then((response) => {
                    app.$data.cveInfo = response.data
                    app.$data.showCVEDetail = true
                })
                .catch(({ response }) => { console.error(response) })
        },
        generateJiraLink(jira_task) {
            return jira_task ? `https://mirantis.jira.com/browse/${jira_task.name}` : ''
        },
        getJiraTaskName(jira_task) {
            return jira_task ? jira_task.name : ''
        },
        getJiraTaskFixVer(jira_task) {
            if (jira_task) {
                return jira_task.fix_version != '' ? jira_task.fix_version : null
            } else {
                return null
            }
        },
        getJiraTaskAffects(jira_task) {
            return jira_task ? jira_task.affected_version : null
        },
        getScanDate(scan_date) {
            const date = new Date(scan_date)
            return date.toLocaleDateString("en-US")
        },
    },
    watch: {
        product: function () {
            this.reloadClusterList()
            this.reloadComponentList()
        },
        cluster: function () { this.reloadComponentList() },
        component: function () { this.reloadImageList() },
        image: function () { this.filterChanged() },
        severities: function () { this.filterChanged() },
        exploitable: function () { this.filterChanged() },
        package_type: function () { this.filterChanged() },
        scanner: function () { this.filterChanged() },
        cve_name_filter: function () { this.filterChanged() },
    },
    async created() {
        const app = this

        this.showLoader();

        app.product_list = []
        await app.$axios.get('/api/product/')
            .then(response => { app.product_list = response.data })
            .catch(error => console.log(error))

        await app.$axios.get('/api/cluster/')
            .then(response => { app.cluster_list = response.data })
            .catch(error => console.log(error))

        await app.$axios.get('/api/component/')
            .then(response => {
                app.componentCount = response.data.length
                app.component_list = response.data
            })
            .catch(error => console.log(error))

        await app.$axios.get('/api/image/')
            .then(response => {
                app.imageCount = response.data.length
                app.image_list = response.data
            })
            .catch(error => console.log(error));

        await app.$axios.get('/api/severity/')
            .then(response => { app.severity_list = response.data })
            .catch(error => console.log(error));

        await app.$axios.get('/api/scanner/')
            .then(response => { app.scanners = response.data })
            .catch(error => console.log(error));

        await app.$axios.get('/api/package_type/')
            .then(response => { app.package_types = response.data })
            .catch(error => console.log(error));

        if (this.$route.query.images) {
            this.image = this.image_list.filter((el) => { return el.id == this.$route.query.images })[0]
        }
        if (this.$route.query.clusters) {
            this.cluster = this.cluster_list.filter((el) => { return el.id == this.$route.query.clusters })[0]
        }
        if (this.$route.query.products) {
            this.product = this.product_list.filter((el) => { return el.id == this.$route.query.products })[0]
        }
        if (this.$route.query.severities) {
            this.severities = this.severity_list.filter((el) => { return this.$route.query.severities.includes(el.id) })
        }
        if (this.$route.query.components) {
            this.component = this.component_list.filter((el) => { return el.id == this.$route.query.components })[0]
        }
        if (this.$route.query.name_contains) {
            this.cve_name_filter = this.$route.query.name_contains
        }
        if (this.$route.query.package_types) {
            this.package_type = this.package_types.filter((el) => { return el.id == this.$route.query.package_types })[0]
        }
        if (this.$route.query.exploitable) {
            this.exploitable = this.exploitable_list.filter((el) => { return el.value == this.$route.query.exploitable })[0]
        }
        if (this.$route.query.severities) {
            this.severities = this.severity_list.filter((el) => { return this.$route.query.severities.includes(el.id) })
        }


        this.reloadCVEList()
    },
}
</script>
